import { FC, MouseEvent, useState } from 'react';
import block from 'utils/bem-css-module';
import { post } from 'utils/fetch';
import { emailRegExp } from 'utils/user';

import { Alert } from 'components/Alert';
import { Button } from 'components/Button';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { COMMON_ERRORS } from 'constants/text';

import style from './before-login-popup.scss';


const b = block(style);

export const Recovery: FC = () => {
	const [email, setEmail] = useState('');
	const [status, setStatus] = useState<{
		type: 'success' | 'error'; message: string | undefined;
	}>({ type: 'success', message: undefined });
	const [loading, setLoading] = useState(false);

	const onSubmit = async (e: MouseEvent): Promise<void> => {
		e.preventDefault();
		const data = new FormData();
		if (!emailRegExp.test(email)) {
			setStatus({ type: 'error', message: COMMON_ERRORS.EMAIL });
			return;
		}
		data.append('email', email);
		try {
			setLoading(true);
			const response = await post('/api/v1/user/request_pass_change_code/', { email });
			if (response.status === 200) {
				setStatus({
					message: 'Если пользователь с такой почтой существует, то письмо было отправлено.',
					type: 'success',
				});
				return;
			}
			if (response.status === 400) {
				setStatus({ message: 'Ошибка в формате почты или пользователь уже залогинен.', type: 'error' });
				return;
			}
			setStatus({ message: 'Не удалось отправить код на почту.', type: 'error' });
		} catch (err) {
			setStatus({ type: 'error', message: err });
		} finally {
			setLoading(false);
		}
	};


	return (
		<>
			<Heading id='before-login-header' size='l' level={1} className={b('heading', { hidden: true })}>
				Восстановление пароля
			</Heading>
			<div className={b('form')} id='before-login-body'>
				{status.message && <Alert type={status.type} className={b('margin')}>{status.message}</Alert>}
				<p className={b('margin')}>
					Введите адрес электронной почты, связанный с вашей учетной записью, чтобы сбросить пароль.
				</p>
				<Input
					label='Ваша почта:'
					type='email'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className={b('input')}
					id='recovery-email-input'
					required={true}
				/>
				<p className={b('margin')}>
					Если письмо с инструкцией по восстановлению пароля не пришло, проверьте папку "Спам".
				</p>
				<Button
					className={b('button')}
					buttonType='success'
					onClick={onSubmit}
					disabled={email === ''}
					loading={loading}
					size='m'
				>
					Отправить
				</Button>
			</div>
		</>
	);
};
